import * as React from 'react';
import {
  Box,
  Container,
  SimpleGrid,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import '../../styles/management.scss';

const Management = () => {
  return (
    <Layout>
      <SEO
        title="Management and Leadership | Ostara"
        description="Meet the experts in agriculture, environmental science, engineering and business leading Ostara to innovate nutrient solutions to feed the world."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Ostara Management"
      />
      <main className="management">
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[5, 5, 5, 10]}
            >
              <Box
                marginTop={[5, 5, 5, 0]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Stack marginTop={[0, 0, 20, 20]} maxW="50rem" spacing={6}>
                  <h1>Management</h1>
                  <p style={{ maxWidth: 550 }}>
                    Ostara is led by experts in agriculture, environmental
                    science, engineering and business.
                  </p>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../../images/management-hero-min.jpeg"
                    alt="Ostara hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>
        <Container className="management-container" w="90%" maxW={1400}>
          <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={6}>
            <Link to="/management/monty-bayer" className="manager">
              <StaticImage
                className="manager__img"
                placeholder="transparent"
                src="../../images/monty-thumb.jpg"
                alt=""
              />
              <Heading as="h3" className="manager__name">
                Monty Bayer
              </Heading>
              <Text className="manager__position">
                Executive Board Chairman, Interim-CEO
              </Text>
            </Link>
            <Link to="/management/terri-west" className="manager">
              <StaticImage
                className="manager__img"
                placeholder="transparent"
                src="../../images/terri-west-thumb.jpg"
                alt=""
              />
              <Heading as="h3" className="manager__name">
                Terri West, CPA, MBA
              </Heading>
              <Text className="manager__position">Chief Financial Officer</Text>
            </Link>
            <Link to="/management/ron-restum" className="manager">
              <StaticImage
                className="manager__img"
                placeholder="transparent"
                src="../../images/ron-restum-thumb.jpg"
                alt=""
              />
              <Heading as="h3" className="manager__name">
                Ron Restum, BBA
              </Heading>
              <Text className="manager__position">
                Chief Commercial Officer
              </Text>
            </Link>
            <Link to="/management/ryan-deberry" className="manager">
              <StaticImage
                className="manager__img"
                placeholder="transparent"
                src="../../images/ryan-deberry-thumb.jpg"
                alt=""
              />
              <Heading as="h3" className="manager__name">
                Ryan DeBerry
              </Heading>
              <Text className="manager__position">
                Chief Operations Officer
              </Text>
            </Link>
            <Link to="/management/matt-kuzma" className="manager">
              <StaticImage
                className="manager__img"
                placeholder="transparent"
                src="../../images/matt-kuzma-thumb.jpg"
                alt=""
              />
              <Heading as="h3" className="manager__name">
                Matthew Kuzma, BAS
              </Heading>
              <Text className="manager__position">
                Vice President, Nutrient Recovery Solutions
              </Text>
            </Link>
            <Link to="/management/ahren-britton" className="manager">
              <StaticImage
                className="manager__img"
                placeholder="transparent"
                src="../../images/ahren-britton-thumb.jpg"
                alt=""
              />
              <Heading as="h3" className="manager__name">
                Ahren Britton, M.A.Sc., P.Eng
              </Heading>
              <Text className="manager__position">
                Chief Technology Officer and Commercial Technology Development
                Leader
              </Text>
            </Link>
          </SimpleGrid>
        </Container>

        <Container className="body-cta-container" maxW={1400}>
          <Box>
            <Link to="/contact">Let’s Talk About Your Nutrient Challenges</Link>
          </Box>
        </Container>
      </main>
    </Layout>
  );
};

export default Management;
